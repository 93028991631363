import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/aws-auth-mapper",
  "date": "11 August 2021",
  "title": "AWS Auth Mapper for EKS",
  "summary": "A project for managing aws-iam-authenticator configuration using Terraform and Kubernetes Controllers",
  "author": "Nick Schuch",
  "tag": "Project",
  "tagColor": "blue",
  "tags": [{
    "name": "project"
  }, {
    "name": "open source"
  }, {
    "name": "aws"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`AWS EKS provides insufficient authentication capabilities for cluster administrators who wish to use industry standard tooling such as Terraform or Ansible.`}</p>
    <p>{`AWS provides an "out of the box" solution for managing cluster authentication using `}<a parentName="p" {...{
        "href": "https://github.com/kubernetes-sigs/aws-iam-authenticator"
      }}>{`AWS IAM Authenticator`}</a>{`.`}</p>
    <p>{`AWS IAM Authenticator allows cluster administrators to map AWS IAM users/roles to internal Kubernetes users/groups using a Kubernetes `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
kind: ConfigMap
metadata:
  name: aws-auth
  namespace: kube-system
data:
  mapRoles: |
    - rolearn: arn:aws:iam::xxxyyyzzz:role/node
      username: system:node:{{EC2PrivateDNSName}}
      groups:
        - system:bootstrappers
        - system:nodes
  mapUsers: |
    - userARN: arn:aws:iam::xxxyyyzzz:user/Alice
      username: alice
      groups:
        - system:masters
`}</code></pre>
    <p>{`While the example above looks simple and easy use, things get very difficult when you want to automate the management of this `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{`
given `}<inlineCode parentName="p">{`mapRoles`}</inlineCode>{` and `}<inlineCode parentName="p">{`mapUsers`}</inlineCode>{` are just raw yaml files with no API for managing these lists of mapped users and roles. If you have more
than one automation tool then they will eventually end up writing configuration over the top of one another.`}</p>
    <p>{`We built `}<a parentName="p" {...{
        "href": "https://github.com/skpr/aws-auth-mapper"
      }}>{`AWS Auth Mapper for EKS`}</a>{` to solve this problem.`}</p>
    <p>{`AWS Auth Mapper provides a set of Kubernetes objects (using `}<inlineCode parentName="p">{`CustomResourceDefinitions`}</inlineCode>{`) for external tools to declare `}<em parentName="p">{`AWS IAM Authenticator`}</em>{` configuration.
These objects are then compiled into the `}<inlineCode parentName="p">{`ConfigMap`}</inlineCode>{` object which is used by `}<em parentName="p">{`AWS IAM Authenticator`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: iamauthenticator.skpr.io/v1beta1
kind: MapRole
metadata:
  name: node
spec:
  roleARN: arn:aws:iam::xxxyyyzzz:role/node
  username: system:node:{{EC2PrivateDNSName}}
  groups:
    - system:bootstrappers
    - system:nodes
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: iamauthenticator.skpr.io/v1beta1
kind: MapUser
metadata:
  name: alice
spec:
  userARN: arn:aws:iam::xxxyyyzzz:user/Alice
  username: alice
  groups:
    - system:masters
`}</code></pre>
    <p>{`AWS Auth Mapper also ships with a Terraform provider for managing the AWS Auth Mapper Kubernetes objects. This means you can provision an EKS cluster
and manage the authentication with the same Terraform manifest while other tools (eg. Ansible or Kubernetes Controllers) can also safely interact with
AWS IAM Authenticator configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`resource "aam_iamauthenticator_v1beta1_maprole" "node" {
  name = "node"

  role_arn = "arn:aws:iam::xxxyyyzzz:role/node"
  username = "system:node:{{EC2PrivateDNSName}}"
  groups = [
    "system:bootstrappers",
    "system:nodes",
  ]
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`resource "aam_iamauthenticator_v1beta1_mapuser" "admin" {
  name = "alice"

  user_arn = "arn:aws:iam::xxxyyyzzz:user/Alice"
  username = "alice"
  groups = [
    "system:masters",
  ]
}
`}</code></pre>
    <p><em parentName="p">{`AWS IAM Authenticator`}</em>{` does provide it's own set of Kubernetes objects but they are flagged as an alpha feature and you need to deviate
from the AWS managed deployment to enable the feature. This means you are not only enabling, but maintaining the deployment of an alpha feature.`}</p>
    <p>{`What we have built allows for Amazon EKS platform operators to safely orchestrate `}<em parentName="p">{`AWS IAM Authenticator`}</em>{` configuration using their preferred tools.`}</p>
    <p>{`For more information see our `}<a parentName="p" {...{
        "href": "https://github.com/skpr/aws-auth-mapper"
      }}>{`Github project`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      